<template>
  <div class="report-xian" ref="imageDom">
    <div class="report-xian-container">
      <report-title
        :model="model"
        @onDownloadPDFClick="onDownloadPDFClick"
        @onDownloadPNGClick="onDownloadPNGClick"
        @onPrintClick="onPrintClick"
        @onShareClick="onShareClick"
      ></report-title>
      <user-info
        :model="model"
        :patientBMI="patientBMI"
        @onInputInput="onInputInput"
        @onSaveClick="onSaveClick"
      ></user-info>
      <section-header title="数据摘要"></section-header>
      <data-abstract :model="model"></data-abstract>
      <section-header title="睡眠时间统计"></section-header>
      <sleep-time-statistics :model="model"></sleep-time-statistics>
      <section-header title="睡眠呼吸事件"></section-header>
      <sleep-event :model="model"></sleep-event>
      <section-header title="睡眠分期时间"></section-header>
      <sleep-period :model="model"></sleep-period>
      <section-header title="血氧统计"></section-header>
      <blood-oxygen :model="model"></blood-oxygen>
      <section-header title="脉率统计"></section-header>
      <pulse-statistics :model="model"></pulse-statistics>
      <!-- 打印预览第一页 -->
      <div style="page-break-after: always"></div>
      <section-header title="监测数据"></section-header>
      <double-line-chart
        v-if="model.isCombineBloodOxygenAndPulse"
        title="睡眠血氧/脉率趋势"
        :dataArray1="model.bloodOxygenChartDataArray"
        :dataArray2="model.pulseChartDataArray"
        :timeTickArray="model.timeTickArray"
        :height="80"
      ></double-line-chart>
      <div v-else>
        <line-chart
          title="睡眠血氧趋势"
          :dataArray="model.bloodOxygenChartDataArray"
          :timeTickArray="model.timeTickArray"
          :height="80"
        ></line-chart>
        <line-chart
          title="睡眠脉率趋势"
          :dataArray="model.pulseChartDataArray"
          :timeTickArray="model.timeTickArray"
          :height="80"
        ></line-chart>
      </div>
      <line-chart
        title="睡眠呼吸趋势"
        :dataArray="model.breathChartDataArray"
        :timeTickArray="model.timeTickArray"
        :height="80"
      ></line-chart>
      <double-line-area-chart
        v-if="model.isCombinePeriodAndEvent"
        title="睡眠分期趋势/睡眠呼吸事件"
        :model="model"
        :timeTickArray="model.timeTickArray"
        :height="80"
      ></double-line-area-chart>
      <div v-else>
        <line-area-chart
          title="睡眠分期趋势"
          :model="model"
          :timeTickArray="model.timeTickArray"
          :height="80"
        ></line-area-chart>
        <line-chart
          title="睡眠呼吸事件"
          :dataArray="model.breathEventChartDataArray"
          :timeTickArray="model.timeTickArray"
          :height="80"
        ></line-chart>
      </div>
      <area-chart
        title="睡眠体动占比"
        :dataArray="model.moveRateChartDataArray"
        :timeTickArray="model.timeTickArray"
        :height="80"
      ></area-chart>
      <area-chart
        title="睡眠体动幅度"
        :dataArray="model.moveRangeChartDataArray"
        :timeTickArray="model.timeTickArray"
        :sn="model.ringSN"
        :height="80"
      ></area-chart>
      <!-- 打印预览第二页分页处 -->
      <div style="page-break-after: always"></div>

      <section-header title="评估建议"></section-header>
      <ahi-bar
        :ahi="model.ahi"
        :ahiTitle="model.ahiTitle"
        :degreeArray="model.ahiDegreeArray"
      ></ahi-bar>
      <editor
        style="z-index: 0"
        :model="model"
        @onSaveClick="onEditorSaveClick"
      ></editor>
      <report-bottom :model="model" />
    </div>
  </div>
</template>

<script>
import ReportTitle from "@c/report-detail/report-title.vue";
import UserInfo from "@c/report-detail/user-info/user-info.vue";
import SectionHeader from "@c/report-detail/section-header.vue";
import DataAbstract from "@c/report-detail/data-abstract/data-abstract.vue";
import SleepTimeStatistics from "@c/report-detail/sleep-time-statistics.vue";
import SleepEvent from "@c/report-detail/sleep-event/sleep-event.vue";
import SleepPeriod from "@c/report-detail/sleep-period/sleep-period.vue";
import BloodOxygen from "@c/report-detail/blood-oxygen/blood-oxygen.vue";
import PulseStatistics from "@c/report-detail/pulse-statistics.vue";
import AhiBar from "@c/report-detail/ahi-bar.vue";
import Editor from "@c/report-detail/editor.vue";
import ReportBottom from "@c/report-detail/report-bottom.vue";
import LineChart from "@c/report-detail/sleep-chart/line-chart.vue";
import LineAreaChart from "@c/report-detail/sleep-chart/line-area-chart.vue";
import AreaChart from "@c/report-detail/sleep-chart/area-chart.vue";
import DoubleLineChart from "@c/report-detail/sleep-chart/double-line-chart.vue";
import DoubleLineAreaChart from "@c/report-detail/sleep-chart/double-line-area-chart.vue";
import WarningHint from "@c/report-detail/warning/invalid-bloodoxygen-warning.vue";

export default {
  components: {
    ReportTitle,
    UserInfo,
    SectionHeader,
    DataAbstract,
    SleepTimeStatistics,
    SleepEvent,
    SleepPeriod,
    BloodOxygen,
    PulseStatistics,
    AhiBar,
    Editor,
    ReportBottom,
    LineChart,
    AreaChart,
    DoubleLineChart,
    WarningHint,
    LineAreaChart,
    DoubleLineAreaChart,
  },

  props: {
    model: Object,
    patientBMI: String,
  },

  methods: {
    onDownloadPDFClick() {
      this.$emit("onDownloadPDFClick");
    },

    onDownloadPNGClick() {
      this.$emit("onDownloadPNGClick");
    },

    onPrintClick() {
      this.$emit("onPrintClick");
    },

    async onShareClick() {
      this.$emit("onShareClick");
    },

    onSaveClick() {
      this.$emit("onSaveClick");
    },

    onInputInput(title, value) {
      this.$emit("onInputInput", title, value);
    },

    onEditorSaveClick(html) {
      this.$emit("onEditorSaveClick", html);
    },
  },
};
</script>

<style lang="scss" scoped>
.report-xian {
  width: 100%;

  &-container {
    left: top;
    width: rem(750);
    padding: 0;
    margin: 0 auto;

    > .user-info {
      margin-top: 5px;
      margin-bottom: 5px;
      :deep(.user-info-top) {
        height: 25px;
        margin-top: 0;
        margin-bottom: 0;
      }
      :deep(.user-info-bottom) {
        height: 25px;
      }
    }

    > .section-header {
      height: 30px;
    }

    > .data-abstract {
      padding: 5px 20px;
    }

    :deep(.sleep-time-statistics) {
      padding: 5px 20px;
    }

    > .sleep-period {
      padding: 5px 20px;
    }

    > .sleep-event {
      :deep(.sleep-event-unit) {
        height: 20px;
      }
    }

    > .blood-oxygen {
      padding: 5px 20px;
      :deep(.blood-oxygen-left) {
        > .blood-oxygen-unit {
          height: 20px;
        }
      }
      :deep(.blood-oxygen-right) {
        > .sleep-period-unit {
          height: 20px;
        }
      }
    }

    > .pulse-statistics {
      padding: 5px 20px;
    }
  }
}
</style>

<style lang="scss">
.el-message-box__message p {
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>

<style lang="scss">
.el-message-box__message p {
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
