<template>
  <div v-if="model.reportId" class="report" ref="imageDom">
    <report-compression
      v-if="showCompressionReport"
      class="report-compression"
      :model="model"
      :patientBMI="patientBMI"
      :userName="model.initialPatientName"
      @onInputInput="onInputInput"
      @onSaveClick="onSaveClick"
      @onEditorSaveClick="onEditorSaveClick"
      @onPrintClick="onPrintClick"
      @onShareClick="onShareClick"
      @onDownloadPNGClick="onDownloadPNGClick"
      @onDownloadPDFClick="onDownloadPDFClick"
    />
    <!-- 西安交通大学附属儿童医院睡眠监测中心 -->
    <report-xian-jiao-tong
      v-else-if="model.reportTemplateType == 18"
      :model="model"
      :patientBMI="patientBMI"
      @onInputInput="onInputInput"
      @onSaveClick="onSaveClick"
      @onEditorSaveClick="onEditorSaveClick"
      @onPrintClick="onPrintClick"
      @onShareClick="onShareClick"
      @onDownloadPNGClick="onDownloadPNGClick"
      @onDownloadPDFClick="onDownloadPDFClick"
    ></report-xian-jiao-tong>
    <div v-else class="report-container">
      <report-title
        :model="model"
        @onDownloadPDFClick="onDownloadPDFClick"
        @onDownloadPNGClick="onDownloadPNGClick"
        @onPrintClick="onPrintClick"
        @onShareClick="onShareClick"
      ></report-title>
      <user-info
        :model="model"
        :patientBMI="patientBMI"
        @onInputInput="onInputInput"
        @onSaveClick="onSaveClick"
      ></user-info>
      <section-header title="数据摘要"></section-header>
      <data-abstract :model="model"></data-abstract>
      <section-header title="睡眠时间统计"></section-header>
      <sleep-time-statistics :model="model"></sleep-time-statistics>
      <section-header title="睡眠呼吸事件"></section-header>
      <sleep-event :model="model"></sleep-event>
      <section-header title="睡眠分期时间"></section-header>
      <sleep-period :model="model"></sleep-period>
      <!-- 打印预览第一页 -->
      <div style="page-break-after: always"></div>
      <section-header title="血氧统计"></section-header>
      <blood-oxygen :model="model"></blood-oxygen>
      <section-header title="脉率统计"></section-header>
      <pulse-statistics :model="model"></pulse-statistics>
      <!-- 南方医科大学顺德医院耳鼻咽喉头颈外科 -->
      <div v-if="model.reportTemplateType != 11">
        <section-header title="监测数据"></section-header>
        <template v-for="(chartType, index) in chartOrder">

          <!-- 血氧/脉率组合图 -->
          <template v-if="chartType === 'bloodOxygenPulse'">
            <double-line-chart v-if="model.isCombineBloodOxygenAndPulse" title="睡眠血氧/脉率趋势"
              :dataArray1="model.bloodOxygenChartDataArray" :dataArray2="model.pulseChartDataArray"
              :timeTickArray="model.timeTickArray">
            </double-line-chart>
            <div v-else>
              <line-chart title="睡眠血氧趋势" :dataArray="model.bloodOxygenChartDataArray"
                :timeTickArray="model.timeTickArray">
              </line-chart>
              <line-chart title="睡眠脉率趋势" :dataArray="model.pulseChartDataArray" :timeTickArray="model.timeTickArray">
              </line-chart>
            </div>
          </template>

          <!-- 单独的血氧趋势图 -->
          <line-chart v-if="chartType === 'bloodOxygen'" title="睡眠血氧趋势" :dataArray="model.bloodOxygenChartDataArray"
            :timeTickArray="model.timeTickArray">
          </line-chart>

          <!-- 其他图表类型... -->
          <line-chart v-if="chartType === 'pulse'" title="睡眠脉率趋势" :dataArray="model.pulseChartDataArray"
            :timeTickArray="model.timeTickArray">
          </line-chart>

          <line-chart v-if="chartType === 'breath'" title="睡眠呼吸趋势" :dataArray="model.breathChartDataArray"
            :timeTickArray="model.timeTickArray">
          </line-chart>

          <template v-if="chartType === 'periodEvent'">
            <double-line-area-chart v-if="model.isCombinePeriodAndEvent" title="睡眠分期趋势/睡眠呼吸事件" :model="model"
              :timeTickArray="model.timeTickArray">
            </double-line-area-chart>
            <div v-else>
              <line-area-chart title="睡眠分期趋势" :model="model" :timeTickArray="model.timeTickArray">
              </line-area-chart>
              <line-chart title="睡眠呼吸事件" :dataArray="model.breathEventChartDataArray"
                :timeTickArray="model.timeTickArray">
              </line-chart>
            </div>
          </template>

          <line-area-chart v-if="chartType === 'period'" title="睡眠分期趋势" :model="model" :timeTickArray="model.timeTickArray">
          </line-area-chart>

          <line-chart v-if="chartType === 'breathEvent'" title="睡眠呼吸事件" :dataArray="model.breathEventChartDataArray" :timeTickArray="model.timeTickArray">
          </line-chart>

          <area-chart v-if="chartType === 'moveRate'" title="睡眠体动占比" :dataArray="model.moveRateChartDataArray"
            :timeTickArray="model.timeTickArray">
          </area-chart>

          <area-chart v-if="chartType === 'moveRange'" title="睡眠体动幅度" :dataArray="model.moveRangeChartDataArray"
            :timeTickArray="model.timeTickArray" :sn="model.ringSN">
          </area-chart>
          <!-- 根据需要添加分页符 -->
          <div v-if="shouldAddPageBreak(index)" style="page-break-after: always"></div>
        </template>
      </div>
      <section-header title="评估建议"></section-header>
      <ahi-bar :ahi="model.ahi" :ahiTitle="model.ahiTitle" :degreeArray="model.ahiDegreeArray"></ahi-bar>
      <editor style="z-index: 0;" :model="model" @onSaveClick="onEditorSaveClick"></editor>
      <report-bottom :model="model" />
      <!-- 四川省中医院 -->
      <div
        class="print-footer"
        id="print-footer"
        v-if="model.reportTemplateType == 5"
      >
        <div class="print-footer1">
          <p>耳鼻咽喉科鼾症门诊：每周一至周六</p>
          <p>地址：成都市金牛区十二桥路39号（地铁专线·中医大省医院站）</p>
        </div>
        <div class="print-footer2">
          <p>门诊睡眠监测预约电话：028-87783460</p>
          <p>病房（第一住院部17楼）：028-87761793</p>
        </div>
      </div>
    </div>
    <warning-hint
      class="report-warning no-print"
      v-if="showWarning"
      @close="onWarningClose"
    ></warning-hint>
  </div>
</template>

<script>
import ReportTitle from "@c/report-detail/report-title.vue";
import UserInfo from "@c/report-detail/user-info/user-info.vue";
import SectionHeader from "@c/report-detail/section-header.vue";
import DataAbstract from "@c/report-detail/data-abstract/data-abstract.vue";
import SleepTimeStatistics from "@c/report-detail/sleep-time-statistics.vue";
import SleepEvent from "@c/report-detail/sleep-event/sleep-event.vue";
import SleepPeriod from "@c/report-detail/sleep-period/sleep-period.vue";
import BloodOxygen from "@c/report-detail/blood-oxygen/blood-oxygen.vue";
import PulseStatistics from "@c/report-detail/pulse-statistics.vue";
import AhiBar from "@c/report-detail/ahi-bar.vue";
import Editor from "@c/report-detail/editor.vue";
import ReportBottom from "@c/report-detail/report-bottom.vue";
import { ReportModel } from "./report-model.js";
import { ReportPreviewTool } from "@js/report-preview-tool.js";
import html2canvas from "html2canvas";
import LineChart from "@c/report-detail/sleep-chart/line-chart.vue";
import LineAreaChart from "@c/report-detail/sleep-chart/line-area-chart.vue";
import AreaChart from "@c/report-detail/sleep-chart/area-chart.vue";
import DoubleLineChart from "@c/report-detail/sleep-chart/double-line-chart.vue";
import DoubleLineAreaChart from "@c/report-detail/sleep-chart/double-line-area-chart.vue";
import { DateTool } from "@js/date-tool.js";
import WarningHint from "@c/report-detail/warning/invalid-bloodoxygen-warning.vue";
import { MessageBox } from "element-ui";
import appHandler from "@js/appHandler.js";
import reportCompression from "@c/report-detail/report-compression/report-compression.vue";
import ReportXianJiaoTong from "./ReportXianJiaoTong.vue";

const CHART_ORDERS = {
  default: [
    'bloodOxygenPulse',  // （血氧/脉率组合图）或者是（睡眠血氧趋势+睡眠脉率趋势）
    'breath',            // 呼吸趋势
    'periodEvent',       // 分期/事件组合图
    'moveRate',          // 体动占比
    'moveRange'          // 体动幅度
  ],
  [REPORT_TEMPLATE.沧州市中心医院]: [
    'bloodOxygen',       // 血氧趋势
    'period',            // 睡眠分期趋势
    'breathEvent',       // 睡眠呼吸事件
    'moveRange',         // 体动幅度
    'pulse',            // 脉率趋势
    'breath',           // 呼吸趋势
    'moveRate'          // 体动占比
  ]
}

export default {
  components: {
    ReportTitle,
    UserInfo,
    SectionHeader,
    DataAbstract,
    SleepTimeStatistics,
    SleepEvent,
    SleepPeriod,
    BloodOxygen,
    PulseStatistics,
    AhiBar,
    Editor,
    ReportBottom,
    LineChart,
    AreaChart,
    DoubleLineChart,
    WarningHint,
    reportCompression,
    LineAreaChart,
    DoubleLineAreaChart,
    ReportXianJiaoTong,
  },
  data() {
    return {
      pageNum: 1,
      model: {},
      operator: "",
      reviewer: "",
      patientName: "",
      patientHeight: "",
      patientAge: "",
      patientDepartment: "",
      patientGender: "",
      patientWeight: "",
      patientBedNumber: "",
      patientDiagnoseNumber: "",
      patientBMI: "",
      editedConclusion: "",
      mobile: "",
      showWarning: false, // 默认不显示warning-hint
      pdfUrl: null,
      reportPdfUrl: null,
      reportPngUrl: null,
      reportId: null,
      clickType: null,
      isLoadingPdf: false,
      reportType: "compression",
      qrUrl:
        "https://bajie-sleep.oss-cn-hangzhou.aliyuncs.com/file/1706604514090.png",
    };
  },

  computed: {
    showCompressionReport() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.北京大学深圳医院呼吸内科睡眠检测中心:
        case REPORT_TEMPLATE.深圳市儿童医院:
          return true;
        default:
          return false;
      }
    },
    chartOrder() {
      return CHART_ORDERS[this.model.reportTemplateType] || CHART_ORDERS.default
    }
  },

  beforeDestroy() {
    this.model = null;
  },
  watch: {
    model(newValue) {
      this.operator = newValue.initialOperator;
      this.reviewer = newValue.initialReviewer;
      this.patientName = newValue.initialPatientName;
      this.patientHeight = newValue.initialPatientHeight;
      this.patientAge = newValue.initialPatientAge;
      this.patientDepartment = newValue.initialPatientDepartment;
      this.patientGender = newValue.initialPatientGender;
      this.patientWeight = newValue.initialPatientWeight;
      this.patientBedNumber = newValue.initialPatientBedNumber;
      this.patientDiagnoseNumber = newValue.initialPatientDiagnoseNumber;
      this.patientBMI = newValue.initialPatientBMI;
      this.editedConclusion = newValue.conclusion;
      this.mobile = newValue.initInitialMobile;
    },

    patientHeight() {
      this.computeBMIValue();
    },

    patientWeight() {
      this.computeBMIValue();
    },
  },
  beforeCreate() {
    if (window.name == "") {
      this.$store.state.loadingShow = true;
      // 在首次进入页面时我们给window.name设置一个固定值(isRefresh)
      window.name = "isRefresh";
    } else if (window.name == "isRefresh") {
      this.$store.state.loadingShow = true;
    }
  },

  created() {
    this.reportDetailRequest();
  },

  methods: {
    addPageNumbers() {
      const footer = document.getElementById("print-footer");
      const pageCounter = document.getElementById("page-counter");

      // 获取组件根元素的高度
      const componentHeight = this.$el.offsetHeight;
      const windowHeight = window.innerHeight;

      // 计算总页数
      const totalPages = Math.ceil(componentHeight / windowHeight);
      console.log("总页数", totalPages);
      for (let i = 1; i <= totalPages; i++) {
        const pageNumber = document.createElement("span");
        pageNumber.textContent = `第 ${i} 页`;

        if (i !== totalPages) {
          pageNumber.innerHTML += " | ";
        }

        pageCounter.appendChild(pageNumber);
      }
    },
    whatOS() {
      let ran = navigator.userAgent;
      let isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
      let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        return "Android";
      } else if (isIOS) {
        return "IOS";
      } else {
        return "web";
      }
    },

    onWarningClose(value) {
      this.showWarning = value;
    },
    onDownloadPDFClick() {
      this.clickType = "pdf";
      this.reportGenerateUrl();
    },
    onDownloadPNGClick() {
      this.clickType = "png";
      this.reportGenerateUrl();
    },

    async reportGenerateUrl() {
      const reportInfo = this.$router.history.current.query;
      let createTimeStr = DateTool.timestampToStr(
        reportInfo.reportCreateTime,
        "M月D日 h：m"
      );
      try {
        const params = {
          reportId: this.reportId,
        };
        const response = await this.$api.reportGenerateUrl(params);
        this.reportPdfUrl = response.pdfUrl;
        this.reportPngUrl = response.pngUrl;
        if (this.clickType == "pdf") {
          if (this.whatOS() == "web") {
            ReportPreviewTool.downloadReport(
              reportInfo.userName,
              this.reportPdfUrl,
              reportInfo.reportCreateTime
            );
          } else {
            appHandler.sendMsg({
              type: "DownloadPDF",
              pngUrl: this.reportPngUrl,
              pdfUrl: this.reportPdfUrl,
            });
          }
        } else if (this.clickType == "png") {
          if (this.whatOS() == "web") {
            ReportPreviewTool.downloadImage(
              reportInfo.userName,
              createTimeStr,
              this.reportPngUrl
            );
          } else {
            appHandler.sendMsg({
              type: "DownloadPNG",
              pngUrl: this.reportPngUrl,
              pdfUrl: this.reportPdfUrl,
            });
          }
        } else if (this.clickType == "print") {
          this.onPrintClick();
        } else {
          this.onShareClick();
        }
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    onPrintClick() {
      this.clickType = "print";
      if (this.whatOS() == "web") {
        // this.addPageNumbers();
        window.print();
      } else {
        if (this.reportPdfUrl == null || this.reportPngUrl == null) {
          this.reportGenerateUrl();
        } else {
          appHandler.sendMsg({
            type: "Print",
            pngUrl: this.reportPngUrl,
            pdfUrl: this.reportPdfUrl,
          });
        }
      }
    },

    async onShareClick() {
      this.clickType = "share";
      if (this.whatOS() == "web") {
        try {
          const reportInfo = this.$router.history.current.query;
          let title = `【NPPV治疗服务睡眠监测报告${
            this.model?.createDate || ""
          }${reportInfo.userName}】`;
          let url = "";
          await this.generatePdf();
          url = this.pdfUrl;
          MessageBox.alert(title + url, "分享内容", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$copyText(title + url);
              this.$toast.showGreen(MSG.copyToClipboardSuccess);
            },
          });
        } catch (error) {
          console.log("onShareClick", error);
          this.$toast.showRed(error);
        }
      } else {
        if (this.reportPdfUrl == null || this.reportPngUrl == null) {
          this.reportGenerateUrl();
        } else {
          appHandler.sendMsg({
            type: "Share",
            pngUrl: this.reportPngUrl,
            pdfUrl: this.reportPdfUrl,
          });
        }
      }
    },

    async generatePdf() {
      if (this.isLoadingPdf) {
        return;
      }
      this.isLoadingPdf = true;
      try {
        let downloadURL = await this.$api.generatePdf({
          url: window.location.href,
          inline: true,
        });
        this.pdfUrl = downloadURL;
      } catch (error) {
        console.log("generatePdfError", error);
      } finally {
        this.isLoadingPdf = false;
      }
    },

    onSaveClick() {
      this.submitRequest();
    },

    onInputInput(title, value) {
      switch (title) {
        case "报告":
          this.operator = value;
          break;
        case "审核":
          this.reviewer = value;
          break;
        case "医师":
          this.reviewer = value;
          break;
        case "姓名":
          this.patientName = value;
          break;
        case "身高(cm)":
          this.patientHeight = value;
          break;
        case "年龄":
          this.patientAge = value;
          break;
        case "科室":
          this.patientDepartment = value;
          break;
        case "性别":
          this.patientGender = value;
          break;
        case "体重(Kg)":
          this.patientWeight = value;
          break;
        case "床位号":
          this.patientBedNumber = value;
          break;
        case "病案号":
          this.patientDiagnoseNumber = value;
          break;
        case "住院号":
          this.patientDiagnoseNumber = value;
          break;
        case "登记号":
          this.patientDiagnoseNumber = value;
          break;
        case "手机号":
          this.mobile = value;
          break;

        default:
          break;
      }
    },

    onEditorSaveClick(html) {
      this.editedConclusion = html;
      this.submitRequest();
    },

    computeBMIValue() {
      let meterHeight = 0;
      if (this.patientHeight) {
        meterHeight = Number.parseFloat(this.patientHeight) / 100;
      } else {
        meterHeight = Number.parseFloat(this.initialPatientHeight) / 100;
      }
      let kgWeight = 0;
      if (this.patientWeight) {
        kgWeight = Number.parseFloat(this.patientWeight);
      } else {
        kgWeight = Number.parseFloat(this.initialPatientWeight);
      }
      const rawBMI = kgWeight / (meterHeight * meterHeight);
      const BMIStr = `${Number(rawBMI.toFixed(1))}`;
      this.patientBMI = BMIStr == "NaN" ? "" : BMIStr;
    },

    async reportDetailRequest() {
      try {
        const params = {
          reportNumber: this.$router.history.current.query.reportURL,
        };
        const response = await this.$api.getSleepReportDetail(params);
        this.reportId = response.reportInfo.id;
        this.reportPdfUrl = response.reportInfo.pdfUrl;
        this.reportPngUrl = response.reportInfo.pngUrl;
        if (response.reportInfo.complete == "缺少血氧") {
          this.showWarning = true;
        }
        this.model = new ReportModel(response);
      } catch (error) {
        console.log("reportDetailRequest", error);
        this.$toast.showRed(error);
      }
    },

    async submitRequest() {
      try {
        const params = {
          reportNumber: this.$router.history.current.query.reportURL,
          age: this.patientAge,
          trueName: this.patientName,
          sex: this.patientGender,
          height: this.patientHeight,
          weight: this.patientWeight,
          bmi: this.patientBMI,
          mzh: this.patientDiagnoseNumber,
          bch: this.patientBedNumber,
          department: this.patientDepartment,
          suggest: this.editedConclusion,
          report: this.operator,
          examine: this.reviewer,
          mobile: this.mobile,
        };
        await this.$api.editReportDetail(params);
        this.$toast.showGreen(MSG.reportEditSucceed);
        this.reportDetailRequest();
      } catch (error) {
        console.log("submitUserInfoRequest", error);
        this.$toast.showRed(error);
      }
    },
    shouldAddPageBreak(index) {
      // 根据图表类型和索引位置决定是否添加分页符
      const chartType = this.chartOrder[index];
      const nextChartType = this.chartOrder[index + 1];
      console.log(this.chartOrder === CHART_ORDERS.default);
      

      // 如果是默认模板
      if (this.chartOrder === CHART_ORDERS.default) {
        // 在呼吸趋势图后添加分页
        if (chartType === 'bloodOxygenPulse') {
          return this.model.isPage2BreakAtPosition1;
        }

        // 在分期/事件组合图后添加分页
        if (chartType === 'breath') {
          return !this.model.isPage2BreakAtPosition1;
        }

        // 在体动占比图后添加分页
        if (chartType === 'moveRate') {
          return this.model.isPage3BreakAtPosition1;
        }

        // 在体动幅度图后添加分页
        if (chartType === 'moveRange') {
          return !this.model.isPage3BreakAtPosition1;
        }
      }
      // 如果是沧州市中心医院模板
      else if (this.model.reportTemplateType === REPORT_TEMPLATE.沧州市中心医院){
        // 血氧趋势后添加分页
        if (chartType === 'period') {
          return true;
        }
        // 睡眠呼吸事件后添加分页
        if (chartType === 'breath') {
          return true
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .report-container {
    padding: 0 !important;
  }

  .no-print {
    display: none !important;
  }

  /* 添加页脚样式 */
  .print-footer {
    display: flex;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    justify-content: space-between;
    // text-align: center;
    /* 将页码居中显示 */
    // counter-reset: page;
    /* 初始化页码计数器 */
    // .pageNumber {
    //   content: "第 " counter(page) " 页";
    //   /* 显示页码 */
    //   display: inline-block;
    //   width: 100%;
    //   text-align: center;
    // }

    @page {
      size: auto;

      @top-right {
        content: "Page " counter(pageNumber);
      }
    }
  }
}

.report {
  width: 100%;

  &-container {
    left: top;
    width: rem(750);
    padding: 0;
    margin: 0 auto;

    &-qr-code {
      display: flex;
      // margin-top: rem(10);
      width: 100%;

      justify-content: center;

      &-img {
        width: rem(80);
        height: rem(80);
      }
    }
  }

  &-compression {
    left: top;
    width: rem(716);
    padding: 0;
    margin: 0 auto;
  }

  &-warning {
    margin-top: rem(10);
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    right: 0;
    text-align: center;
  }
}
</style>

<style lang="scss">
.el-message-box__message p {
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>

<style lang="scss">
.el-message-box__message p {
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
