<template>
  <chart-shell :title="title">
    <div class="double-line-bar-chart-legend">
      <div class="double-line-bar-chart-legend-installment">
        <div class="double-line-bar-chart-legend-installment-rect"></div>
        <div class="double-line-bar-chart-legend-installment-title">
          分期趋势(期)
        </div>
      </div>
      <div class="double-line-bar-chart-legend-leave">
        <div class="double-line-bar-chart-legend-leave-rect"></div>
        <div class="double-line-bar-chart-legend-leave-title">离床数据</div>
      </div>
      <div class="double-line-bar-chart-legend-continued">
        <div class="double-line-bar-chart-legend-continued-rect"></div>
        <div class="double-line-bar-chart-legend-continued-title">
          持续时间(秒)
        </div>
      </div>
    </div>
    <div>
      <div
        class="double-line-bar-chart"
        :id="title"
        :style="{ height: `${height}px` }"
      ></div>
    </div>
  </chart-shell>
</template>

<script>
import ChartShell from "@c/report-detail/sleep-chart/chart-shell.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    ChartShell,
  },

  props: {
    title: String,
    model: Object,
    timeTickArray: Array,
    height: {
      type: Number,
      default: 190,
    },
  },

  data() {
    return {};
  },

  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.title));

    this.renderChart();
  },

  methods: {
    renderChart() {
      this.chart.setOption({
        xAxis: [
          {
            type: "time",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000000",
                width: 1,
              },
            },
            axisLabel: {
              color: "#999999",
              show: true,
              showMaxLabel: true,
              showMinLabel: true,
              formatter: function (value, index) {
                var date = new Date(Math.round(value));
                var hour = date.getHours();
                var minutes = date.getMinutes();

                if (date.getHours() < 10) {
                  hour = "0" + date.getHours();
                }
                if (date.getMinutes() < 10) {
                  minutes = "0" + date.getMinutes();
                }
                var texts = [hour, minutes];

                // if (index === 0 || index == 6) {
                //   texts = [hour, minutes];
                // } else {
                //   if (minutes = '00') {
                //     texts = [hour, minutes];
                //   }
                // }
                return texts.join(":");
              },
              fontSize: "11", //字体大小
            },
            splitLine: {
              show: false,
              color: "#C1C1C1",
            },
            axisTick: {
              //x轴刻度线
              show: true,
              lineStyle: {
                width: 1,
              },
            },
          },
          {
            type: "time",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#000000",
                width: 1,
              },
            },
            axisLabel: {
              show: false,
              showMaxLabel: true,
              showMinLabel: true,
              formatter: function (value, index) {
                var date = new Date(Math.round(value));
                var hour = date.getHours();
                var minutes = date.getMinutes();

                if (date.getHours() < 10) {
                  hour = "0" + date.getHours();
                }
                if (date.getMinutes() < 10) {
                  minutes = "0" + date.getMinutes();
                }
                var texts = [hour, minutes];

                // if (index === 0 || index == 6) {
                //   texts = [hour, minutes];
                // } else {
                //   if (minutes = '00') {
                //     texts = [hour, minutes];
                //   }
                // }
                return texts.join(":");
              },
              fontSize: "11", //字体大小
            },
            splitLine: {
              show: false,
              color: "#C1C1C1",
            },
            axisTick: {
              //x轴刻度线
              show: false,
              lineStyle: {
                width: 1,
              },
            },
          },
        ],
        yAxis: [
          {
            name: "",
            type: "value",
            min: 0,
            max: 4,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000000",
                width: 1,
              },
            },
            axisTick: {
              //Y轴刻度线
              show: {
                formatter: function (value, index) {
                  var result = false;
                  switch (value) {
                    case 0:
                      true;
                      break;

                    default:
                      false;
                  }
                  return result;
                },
              },
              lineStyle: {
                width: 1,
              },
            },
            splitLine: {
              show: false,
            },

            axisLabel: {
              fontSize: "11", //字体大小
              color: "#999999",
              formatter: function (value, index) {
                var result = "";

                switch (value) {
                  case 1:
                    result = "深睡";
                    break;
                  case 2:
                    result = "浅睡";
                    break;
                  case 3:
                    result = "眼动";
                    break;
                  case 4:
                    result = "清醒";
                    break;
                  default:
                    "";
                }
                return result;
              },
            },
          },
          {
            name: "睡眠呼吸事件",
            type: "value",
            min: 0,
            max: 80,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000000",
                width: 1,
              },
            },
            axisTick: {
              //Y轴刻度线
              show: true,
              lineStyle: {
                width: 1,
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              fontSize: "11", //字体大小
              color: "#999999",
            },
          },
        ],

        grid: {
          right: "3.3%",
          left: "5%",
          top: "5%",
          bottom: "10%",
        },
        series: [
          {
            name: "离床数据",
            type: "line",
            symbol: "none",

            data: this.model.breathLeavePeriodChartDataArray.map((item) => [
              item.xValue,
              item.yValue,
            ]),
            lineStyle: {
              width: 1,
            },
            color: "#DCDCDC",
            connectNulls: false,

            areaStyle: {
              color: "#DCDCDC",
              opacity: 1.0,
            },
            emphasis: {
              focus: "none",
              areaStyle: {
                color: "#DCDCDC",
                opacity: 1.0, // 可根据需要调整透明度
              },
            },
            yAxisIndex: 0,
            xAxisIndex: 0,
            cursor: "default", // 添加这一行来去除手型小手指
          },
          {
            name: "睡眠分期趋势",
            type: "line",
            symbol: "none",
            data: this.model.breathPeriodChartDataArray?.map((item) => [
              item.xValue,
              item.yValue,
            ]),
            lineStyle: {
              width: 2,
            },
            color: "#003E86",
            connectNulls: false,

            yAxisIndex: 0,
            xAxisIndex: 0,
            cursor: "default", // 添加这一行来去除手型小手指
          },
          {
            name: "睡眠呼吸事件",
            type: "bar",
            symbol: "none",
            data: this.model.breathEventChartDataArray.map((item) => [
              item.xValue,
              item.yValue,
            ]),
            color: "#DC143C",
            yAxisIndex: 1,
            xAxisIndex: 1,
          },
        ],
      });

      this.chart.getZr().on("mousemove", (param) => {
        var pointInPixel = [param.offsetX, param.offsetY];
        if (this.chart.containPixel("grid", pointInPixel)) {
          //若鼠标滑过区域位置在当前图表范围内 鼠标设置为小手
          this.chart.getZr().setCursorStyle("default");
        } else {
          this.chart.getZr().setCursorStyle("default");
        }
      });
    },

    formatterToolTip(params) {
      var tip = "";
      let value = "";
      let unit = "";
      // console.log("tooltip", params);
      // // let seriesName = item.seriesName;

      params.forEach((item, index) => {
        let timeStr1 = "";
        if (index == 0) {
          const timeStr = DateTool.millisecondTimestampToStr(
            item.data[0],
            "h:m"
          );
          tip += timeStr + "<br />";
          timeStr1 = timeStr;
        }

        const marker = item.marker;
        let seriesName = item.seriesName;

        //  console.log("seriesName",seriesName);
        if (seriesName == "睡眠分期趋势") {
          switch (item.value[1]) {
            case 4:
              value = "清醒";
              break;
            case 3:
              value = "眼动";
              break;
            case 2:
              value = "浅睡";
              break;
            case 1:
              value = "深睡";
              break;
            case null:
              value = "离床";
              break;
            default:
              break;
          }
        } else if (seriesName == "离床数据") {
          if (item.value[1] == 4) {
            value = "离床";
          } else {
            value = "-";
          }
        } else if (seriesName == "睡眠呼吸事件") {
          if (item.value[1] == "0") {
            value = "-";
          } else {
            value = item.value[1] + "秒";
          }
        }

        // // if(seriesName == "")
        tip +=
          marker +
          "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
          value +
          "<br />";
      });

      return tip;
    },
  },
};
</script>

<style lang="scss" scoped>
.double-line-bar-chart-legend {
  margin-top: rem(5);
  font-size: rem(12);
  color: #8d8d8d;
  padding: 0 rem(30);
  display: flex;

  &-installment {
    display: flex;

    &-rect {
      width: rem(12);
      height: rem(12);
      background-color: #003e86;
    }

    &-title {
      margin-left: rem(8);
    }
  }

  &-leave {
    margin-left: rem(30);
    display: flex;

    &-rect {
      width: rem(12);
      height: rem(12);
      background-color: #dcdcdc;
    }

    &-title {
      margin-left: rem(8);
    }
  }

  &-continued {
    margin-left: rem(30);
    display: flex;

    &-rect {
      width: rem(12);
      height: rem(12);
      background-color: #dc143c;
    }

    &-title {
      margin-left: rem(8);
    }
  }
}

.double-line-bar-chart {
  width: 100%;
  height: rem(190);
  box-sizing: border-box;
  padding: 0 rem(25);
  margin-top: rem(10);
  margin-bottom: rem(10);
}
</style>
