<template>
  <chart-shell :title="title">
    <div>
      <div
        class="double-line-chart"
        :id="title"
        :style="{ height: `${height}px` }"
      ></div>
    </div>
  </chart-shell>
</template>

<script>
import ChartShell from "@c/report-detail/sleep-chart/chart-shell.vue";
import { DateTool } from "@js/date-tool.js";
import { DualAxes } from "@antv/g2plot";
import { SleepChartTool } from "@c/report-detail/sleep-chart/sleep-chart-tool.js";

export default {
  components: {
    ChartShell,
  },

  props: {
    title: String,
    dataArray1: Array,
    dataArray2: Array,
    timeTickArray: Array,
    height: {
      type: Number,
      default: 190,
    },
  },

  data() {
    return {
      chartConfig: [],
    };
  },

  mounted() {
    this.configureChart();
  },

  methods: {
    configureChart() {
      switch (this.title) {
        case "睡眠血氧/脉率趋势":
          let valueArray = this.dataArray2.map((item) => {
            return item.yValue ? item.yValue : 0;
          });
          const yMax = Math.max(...valueArray);
          this.chartConfig = [
            {
              yMin: 40,
              yMax: 100,
              yTickCount: 7,
              title: "血氧",
              unit: "%",
              color: "#FF7C7C",
              lineWidth: 0.8,
            },
            {
              yMin: 20,
              yMax: yMax > 140 ? yMax : 140,
              yTickCount: 7,
              title: "脉率",
              unit: "bpm",
              color: "#860039",
              lineWidth: 0.8,
            },
          ];
          break;
        case "睡眠分期趋势/睡眠呼吸事件":
          let eventValueArray = this.dataArray2.map((item) => {
            return item.yValue ? item.yValue : 0;
          });
          const eventYMax = Math.max(...eventValueArray);
          this.chartConfig = [
            {
              yMin: 0,
              yMax: 4,
              yTickCount: 5,
              title: "分期趋势",
              unit: "期",
              color: "#003E86",
              lineWidth: 2,
            },
            {
              yMin: 0,
              yMax: eventYMax > 60 ? eventYMax : 60,
              yTickCount: 5,
              title: "持续时间",
              unit: "秒",
              color: "#DE2146",
              lineWidth: 0.8,
            },
          ];
          break;
        default:
          break;
      }
      this.renderChart();
    },

    renderChart() {
      let dualAxesChart = new DualAxes(this.title, {
        //数据
        data: [this.dataArray1, this.dataArray2],
        xField: "xValue",
        yField: ["yValue", "yValue"],
        meta: {
          xValue: {
            type: "time",
            alias: "时间",
            mask: "HH:mm",
            tickMethod: () => {
              return this.timeTickArray;
            },
          },
        },
        //x轴
        xAxis: {
          line: {
            style: {
              stroke: "#333",
            },
          },
          tickLine: {
            style: {
              stroke: "#333",
            },
          },
        },
        //y轴
        yAxis: this.chartConfig.map((item, index) => {
          return {
            min: item.yMin,
            max: item.yMax,
            tickCount: item.yTickCount,
            grid: null,
            line: {
              style: {
                stroke: "#333",
              },
            },
            tickLine: {
              style: {
                stroke: "#333",
              },
            },
            label: {
              formatter: (item) => {
                //睡眠分期趋势
                if (this.title == "睡眠分期趋势/睡眠呼吸事件" && index == 0) {
                  return SleepChartTool.sleepPeriodStatusToStr(item);
                } else {
                  return item;
                }
              },
            },
          };
        }),
        geometryOptions: this.chartConfig.map((item) => {
          return {
            geometry: "line",
            color: item.color,
            lineStyle: {
              lineWidth: item.lineWidth,
            },
          };
        }),
        legend: {
          custom: true,
          items: this.chartConfig.map((item) => {
            return {
              name: `${item.title}(${item.unit})`,
              marker: {
                symbol: "square",
                style: {
                  fill: item.color,
                },
                clickable: false,
              },
            };
          }),
        },
        tooltip: {
          showContent: this.title == "睡眠血氧/脉率趋势",
          customItems: (originalItems) => {
            return this.chartConfig.map((item, index) => {
              return {
                name: item.title,
                value: `${originalItems[index].value}${item.unit}`,
              };
            });
          },
        },
      });
      dualAxesChart.render();
    },
  },
};
</script>

<style lang="scss" scoped>
.double-line-chart {
  width: 100%;
  box-sizing: border-box;
  padding: 0 rem(25);
  margin-top: rem(10);
  margin-bottom: rem(10);
}
</style>
